import '../../styles/pages/spring-summer-2024/a-family-of-titan-scholars.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';

import Hero from '../../images/spring-summer-2024/a-family-of-titan-scholars/a-family-of-titan-scholars-hero-1920x1080.jpg';
import socialBanner from "../../images/spring-summer-2024/social-banners/a-family-of-titan-scholars-social-banners-1200x630.jpg";
import titelGraphic from "../../images/spring-summer-2024/a-family-of-titan-scholars/a-family-of-titan-scholars-title-380x245.svg";
import ctaBanner from '../../images/spring-summer-2024/a-family-of-titan-scholars/a-family-of-titan-scholars-cta-banner-1920x515.jpg';

import afamilyoftitanscholarsIMG1 from '../../images/spring-summer-2024/a-family-of-titan-scholars/a-family-of-titan-scholars-image-1-533x800.jpg';
import afamilyoftitanscholarsIMG2 from '../../images/spring-summer-2024/a-family-of-titan-scholars/a-family-of-titan-scholars-image-2-533x800.jpg';
import afamilyoftitanscholarsIMG3 from '../../images/spring-summer-2024/a-family-of-titan-scholars/a-family-of-titan-scholars-image-1-800x533.jpg';
import afamilyoftitanscholarsIMG4 from '../../images/spring-summer-2024/a-family-of-titan-scholars/a-family-of-titan-scholars-image-2-800x533.jpg';
import afamilyoftitanscholarsIMG5 from '../../images/spring-summer-2024/a-family-of-titan-scholars/a-family-of-titan-scholars-image-3-800x533.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'A Family of Cal State Fullerton Scholars';
    var pageDesc = 'First-generation students and siblings Pablo, Rebeca, Ana and Andy De La Cruz are recipients of top scholarships awarded through CSUF’s Center for Scholars.';
    var slug = 'a-family-of-titan-scholars';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [
      {
        src: afamilyoftitanscholarsIMG3,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
      {
        src: afamilyoftitanscholarsIMG4,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
      
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt='Four Titans One Legacy' /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">The De La Cruzes are keeping scholarships in the family, with four siblings in the President’s Scholars Program and one in the Abrego Future Scholars Program.
</p>
            <span className="animated fadeInDown delay-1s byline">By Taylor Arrey / Photos by Matt Gush</span>
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>


          <p><span className="intro-text">Sitting in an empty hotel room</span> during a business trip, Pablo De La Cruz notices the silence.</p>
<p>There are no soccer matches on television, no lively debates about films and books, and not a hint of laughter radiating through the space. Taking out his phone, he hits the FaceTime button. Within seconds, all six of his siblings and his parents appear on the screen smiling and laughing — he immediately feels at home.</p>
<p>The Cal State Fullerton alumnus, now a mechanical engineer in aerospace and defense, cherishes this tight-knit family relationship.</p>
<p>&ldquo;When we&rsquo;re all together, those are some of my favorite times,&rdquo; says Pablo &rsquo;20 (B.S. mechanical engineering).</p>
<p>An honors student out of Troy High School in 2016, Pablo looked for colleges to support his ambitious academic goals and allow him to be close to home.</p>
<p>Cal State Fullerton&rsquo;s <a href="https://www.fullerton.edu/presidentsscholars/" title="President’s Scholars Program" target="_blank">President&rsquo;s Scholars Program</a> gave him the opportunity to have both. A merit-based, full scholarship, the program provides opportunities in academics, leadership, service and mentorship to help students earn college degrees.</p>
<p>Two years later, his younger sister Rebeca earned the same scholarship, and the De La Cruz legacy at Cal State Fullerton began.</p>
<p>They all found community in CSUF&rsquo;s <a href="https://www.fullerton.edu/scholars/" title="Center for Scholars" target="_blank">Center for Scholars</a>, where student scholars come together to support one another, participate in service projects and explore  leadership opportunities.</p>
<figure className='align-right large align-outside'>
      <img src={afamilyoftitanscholarsIMG1} alt="" />
 <figcaption>Pablo and Andy De La Cruz</figcaption>
</figure>
    

<h2>Brothers in Engineering</h2>
<p>A first-generation student, Pablo&rsquo;s path to becoming a mechanical engineer started with Legos.</p>
<p>&ldquo;I&rsquo;ve been collecting and building sets for as long as I can remember, so for me, engineering is like working with big kid Legos,&rdquo; says Pablo.</p>
<p>Before beginning his mechanical engineering career in 2020, Pablo gained hands-on experience as captain of Cal State Fullerton&rsquo;s Titan Aero team, a group of student engineers who design and build a small unmanned cargo plane.</p>
<p>For his senior honors project, he created the Titan Aero Mission Planner, a handbook with information and resources passed along to the next generation of Titan Aero team captains.</p>
<p>It was this wealth of knowledge and experience that impressed a recruiter at one of the university&rsquo;s career fairs and landed Pablo a job.</p>
<p>&ldquo;The only thing I know for certain is that professionally, I wouldn&rsquo;t be where I am right now without Cal State Fullerton,&rdquo; says Pablo, who assists with radar designs for defensive systems.</p>
<p>His brother, Andy, is a second-year engineering student and a member of the <a href="https://www.fullerton.edu/futurescholars/" title="Abrego Future Scholars Program" target="_blank">Abrego Future Scholars Program</a> for underrepresented, first-generation students.</p>
<p>Supported with scholarships, academic resources and mentorship, Andy is involved in co-curricular activities such as the Titan Formula Racing team, where he serves as a team lead and oversees a group of six engineers as they design, build and test a functional tool cart.</p>
<blockquote>
  <p>The only thing I know for certain is that professionally, I wouldn’t be where I am right now without Cal State Fullerton.&nbsp;”</p>
  <cite>Pablo De La Cruz ’20</cite>
</blockquote>

<figure className='align-left large align-outside'>
      <img src={afamilyoftitanscholarsIMG2} alt="" />
      <figcaption>Ana and Rebeca De La Cruz</figcaption>
</figure>
    

<h2>Two Hearts for Patient Care</h2>
<p>Blazing her own path, Rebeca &rsquo;22 (B.A. communicative disorders, Spanish) chose to continue her graduate study in communicative disorders at CSUF.</p>
<p>A fan of Tolkien&rsquo;s &ldquo;The Lord of the Rings,&rdquo; she was amazed by the fictional language the characters spoke, and that curiosity sparked her interest in speech and language. When her dad was diagnosed with cancer in 2016, she realized she wanted to use her passion for language to help people.</p>
<p>&ldquo;I remember sitting in one of my dad&rsquo;s sessions, and seeing the patient-centered care. I thought to myself, &lsquo;This is what I want to do in the future,&rsquo;&rdquo; recalls Rebeca, an aspiring speech language pathologist.</p>
<p>As an undergraduate student, Rebeca fueled her love for language by working in the College of Communications&rsquo; Speech and Hearing Clinic, where she provided support to clients, families, graduate clinicians, staff and faculty.</p>
<p>She also volunteered at St. Jude Medical Center, held leadership positions in student organizations, conducted undergraduate research on bilingualism, and presented her research at national and international conferences.</p>
<p>For the youngest President&rsquo;s Scholar, Ana, it was also her father&rsquo;s diagnosis — and recovery — that inspired her to pursue nursing. Her career goal is to work in pediatric oncology and help people the way the nurses helped her family.</p>
<p>&ldquo;Seeing how nurses interact with people and their drive to do everything possible for their patients — I just really want to do that, too,&rdquo; says Ana.</p>

<figure className='align-wide'>
      <img src={afamilyoftitanscholarsIMG4} alt="" />
      <figcaption>Maria Elena and Eduardo De La Cruz</figcaption>
</figure>


<h2>More Than a Scholarship</h2>
<p>It wasn&rsquo;t long after the De La Cruz siblings chose Cal State Fullerton that they realized the scholar programs are about more than just covering tuition.</p>
<p>&ldquo;The President&rsquo;s Scholars Program has helped me come out of my shell,&rdquo; says Ana. &ldquo;It&rsquo;s nice having that comfort and home base during my first year.&rdquo;</p>
<p>Pablo adds that on top of relieving the financial stress of paying for college, he&rsquo;s developed stronger relationships with his siblings, learned the importance of community service, and gained professional and personal skills that he uses every day.</p>
<figure className='align-left large align-outside'>
      <img src={afamilyoftitanscholarsIMG5} alt="" />
      <figcaption>Maria Elena and Alejandro De La Cruz</figcaption>
</figure>
<p>&ldquo;My grandfather was the last person to go to college back in El Salvador,&rdquo; says Pablo. &ldquo;My parents really stressed my education being debt-free. The President&rsquo;s Scholars Program achieved that, and it was close to home.&rdquo;</p>

<p>Alejandro De La Cruz will continue his family’s legacy as an incoming President’s Scholar for the 2024-25 academic year. He plans to study computer engineering.</p>
<p>For their mother, Maria Elena, the impact that CSUF has had on her family is truly extraordinary.</p>
<p>&ldquo;CSUF has provided our children the opportunity to have an excellent academic education and grow as &nbsp;individuals,&rdquo; says Maria Elena. &ldquo;As parents, we feel extremely grateful to CSUF. We are grateful for the university&rsquo;s enthusiasm and passion in providing a concrete way, a reality where well-rounded higher education is achievable. It can be done.&rdquo; <span className="end-mark" /></p>
<figure className='align-wide'>
      <img src={afamilyoftitanscholarsIMG3} alt="" />
      <figcaption>The De La Cruz family </figcaption>
</figure>



          
          </div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the Center for Scholars</span><br />
              <a href="https://give.fullerton.edu/cfs" target='_blank' rel='noreferrer'>give.fullerton.edu/cfs</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 